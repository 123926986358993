"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModernSimplifiedMobileView = void 0;
const React = require("react");
const TeamsLogo_1 = require("../common/TeamsLogo");
const teamsLogoImg = require("../../images/v2/logo_teams.svg");
const tflCommunityBannerImg = require("../../images/v3/tfl_community_banner.png");
const tflCommunityEventBannerImg = require("../../images/v3/tfl_community_event_banner.png");
const teamsInsiderBannerImg = require("../../images/v3/tfl_insiders_banner.png");
const schoolConnectionBannerImg = require("../../images/v3/school_connection_banner.png");
const tflConversationInviteImg = require("../../images/v2/tfl_conversation_invite.svg");
const tflMeetingInviteImg = require("../../images/v2/tfl_meeting_invite.svg");
const tflSyncContactsInviteImg = require("../../images/v2/tfl_sync_contacts_invite.svg");
const tflSkypeBannerImg = require("../../images/v3/tfl_skype_banner.png");
const JoinLauncherContext_1 = require("../../contexts/join-launcher/JoinLauncherContext");
const JoinInfo_1 = require("../../models/JoinInfo");
const TelemetryEvents_1 = require("../../models/telemetry/TelemetryEvents");
const Logger_1 = require("../../services/Logger");
const TranslationService_1 = require("../../services/TranslationService");
const Button_1 = require("../common/Button");
const Footer_1 = require("../common/Footer");
const Spacer_1 = require("../common/Spacer");
const Translate_1 = require("../common/Translate");
const View_1 = require("../common/View");
require("./ModernSimplifiedMobileView.scss");
const Environment_1 = require("../../models/Environment");
const EnvironmentService_1 = require("../../services/EnvironmentService");
const UrlHelper_1 = require("../../helpers/UrlHelper");
const DownloadService_1 = require("../../services/DownloadService");
const CoreContext_1 = require("../../contexts/CoreContext");
const Utils_1 = require("../../helpers/Utils");
const LaunchType_1 = require("../../models/LaunchType");
const ServerParamsService_1 = require("../../services/ServerParamsService");
const ConfigurationService_1 = require("../../services/ConfigurationService");
const CommunityEventsHelper_1 = require("../../helpers/CommunityEventsHelper");
const tflSkypeUnsupportedBannerImg = require("../../images/v3/tfl_skype_unsupported.png");
const storeRedirectionTimeoutMs = 2000;
class ModernSimplifiedMobileView extends React.Component {
    constructor(props) {
        super(props);
        this.finalPanelViewReported = false;
        this.renderModalDialogBox = (dialogDetailString) => (React.createElement("div", { id: "modalDialogContainer", className: "modalDialogOuterContainer", onClick: this.toggleShowDialogBoxState },
            React.createElement("div", { className: "dialogBoxContainer" },
                React.createElement("div", { className: "dialogBoxHeader" },
                    React.createElement(Translate_1.Translate, { i18n: "mobile_no_copy_dialog_box_header" })),
                React.createElement("div", { className: "dialogBoxDetails" },
                    React.createElement(Translate_1.Translate, { i18n: dialogDetailString })),
                React.createElement("div", { className: "dialogBoxButtonsConatiner" },
                    React.createElement("button", { className: "dialogBoxButton dialogBoxContinueButton", "data-tid": "continueDialogButton", onClick: () => {
                            this.setState({
                                showNoCopyDialogBox: false,
                                isCopyToClipboardChecked: false,
                            });
                        } },
                        React.createElement(Translate_1.Translate, { i18n: "mobile_no_copy_dialog_box_continue_btn" })),
                    React.createElement("button", { className: "dialogBoxButton dialogBoxCancelButton", "data-tid": "cancelDialogButton", onClick: this.toggleShowDialogBoxState },
                        React.createElement(Translate_1.Translate, { i18n: "mobile_no_copy_dialog_box_cancel_btn" }))))));
        this.renderDownloadAppSection = (joinInfo) => (React.createElement("div", { className: "downloadApp" },
            React.createElement(Translate_1.Translate, { i18n: "download_app_prompt" }),
            React.createElement(CoreContext_1.CoreContextConsumer, null, ({ launcherService }) => (React.createElement("div", { className: "hyperlink" },
                React.createElement("a", { onClick: () => {
                        const moduleName = EnvironmentService_1.default.getOS() === Environment_1.OperatingSystems.iOS
                            ? TelemetryEvents_1.ModuleNames.DownloadAppleStore
                            : TelemetryEvents_1.ModuleNames.DownloadGooglePlay;
                        this.reportPanelAction(moduleName, joinInfo);
                        this.getLaunchUrlAndCopyToClipboardAsNeeded(moduleName, launcherService);
                        this.navUserToStore(launcherService);
                    }, id: "downloadApp" },
                    React.createElement(Translate_1.Translate, { i18n: "hdr_generic_download_btn" })))))));
        this.onCheckboxClicked = () => {
            if (this.state.isCopyToClipboardChecked) {
                this.toggleShowDialogBoxState();
            }
            else {
                this.toggleCopyToClipboardState();
            }
        };
        this.navUserToStore = (launcherService) => {
            const storeType = EnvironmentService_1.default.getOS() === Environment_1.OperatingSystems.iOS
                ? DownloadService_1.AppStoreType.Apple
                : DownloadService_1.AppStoreType.Google;
            const storeUrl = DownloadService_1.default.getAppStoreUrl(storeType, launcherService);
            UrlHelper_1.UrlHelper.redirect(storeUrl);
        };
        this.toggleCopyToClipboardState = () => {
            this.setState({
                isCopyToClipboardChecked: !this.state.isCopyToClipboardChecked,
            });
        };
        this.toggleShowDialogBoxState = () => {
            this.setState({ showNoCopyDialogBox: !this.state.showNoCopyDialogBox });
        };
        this.state = {
            shouldRenderCopyLinkToClipboardCheckbox: EnvironmentService_1.default.getOS() === Environment_1.OperatingSystems.iOS,
            isCopyToClipboardChecked: true,
            showNoCopyDialogBox: false,
            isLaunching: false,
        };
    }
    render() {
        let bannerSrc;
        let logo;
        let logoClass = "";
        let illustration;
        let title;
        let members;
        let description;
        let addToCalendar;
        let viewDetails;
        let getTeamsAndJoinButtonTitle;
        let copyToClipboardCheckboxText;
        let noCopyDialogDetailText;
        const illustrationClass = "illustration";
        const getHelpJoiningTextLink = "btn_get_help_joining";
        const isCommunityEventAndEnabled = this.props.featureFlags.enableTflCommunityEvents &&
            CommunityEventsHelper_1.CommunityEventsHelper.isCommunityEventByUrl(this.props.launchUrl);
        const isSkypeSupportedURL = this.props.joinType === JoinInfo_1.JoinType.TflSkype &&
            UrlHelper_1.UrlHelper.isSkypeSupportedURL(this.props.launchUrl);
        // logo = teamsLogoImg;
        switch (this.props.joinType) {
            case JoinInfo_1.JoinType.Message:
            case JoinInfo_1.JoinType.TflCommunity:
                bannerSrc = tflCommunityBannerImg;
                logo = teamsLogoImg;
                title = "hdr_mobile_community_title";
                getTeamsAndJoinButtonTitle = "btn_join_community";
                copyToClipboardCheckboxText = "mobile_community_copy_link_checkbox_label";
                noCopyDialogDetailText = "mobile_community_no_copy_dialog_box_details";
                break;
            case JoinInfo_1.JoinType.Meetup:
            case JoinInfo_1.JoinType.MeetupWithCode:
                if (isCommunityEventAndEnabled) {
                    bannerSrc = tflCommunityEventBannerImg;
                    title = "hdr_community_event_title";
                    getTeamsAndJoinButtonTitle = "btn_mobile_join_community_event";
                    copyToClipboardCheckboxText = "mobile_community_event_copy_link_checkbox_label";
                    noCopyDialogDetailText = "mobile_community_event_no_copy_dialog_box_details";
                }
                else {
                    illustration = tflMeetingInviteImg;
                    title = "hdr_mobile_meeting_title";
                    getTeamsAndJoinButtonTitle = "btn_mobile_join_meeting";
                    copyToClipboardCheckboxText = "mobile_meeting_copy_link_checkbox_label";
                    noCopyDialogDetailText = "mobile_meeting_no_copy_dialog_box_details";
                }
                break;
            case JoinInfo_1.JoinType.TflContactSync:
                illustration = tflSyncContactsInviteImg;
                title = "hdr_mobile_contact_sync_title";
                getTeamsAndJoinButtonTitle = "btn_sync_contacts";
                copyToClipboardCheckboxText = "mobile_contact_sync_copy_link_checkbox_label";
                noCopyDialogDetailText = "mobile_contact_sync_no_copy_dialog_box_details";
                break;
            case JoinInfo_1.JoinType.TeamsInsider:
                bannerSrc = teamsInsiderBannerImg;
                logo = teamsLogoImg;
                title = "hdr_teams_insider_title";
                getTeamsAndJoinButtonTitle = "btn_mobile_join_insiders";
                copyToClipboardCheckboxText = "mobile_teams_insider_copy_link_checkbox_label";
                noCopyDialogDetailText = "mobile_teams_insider_no_copy_dialog_box_details";
                description = (React.createElement("p", { className: "description" },
                    React.createElement(Translate_1.Translate, { i18n: "hdr_teams_insider_description" })));
                break;
            case JoinInfo_1.JoinType.SchoolConnection:
                bannerSrc = schoolConnectionBannerImg;
                logo = undefined;
                title = "hdr_school_connection_title";
                getTeamsAndJoinButtonTitle = "btn_mobile_join_school_connection";
                copyToClipboardCheckboxText = "mobile_school_connection_copy_link_checkbox_label";
                noCopyDialogDetailText = "mobile_school_connection_no_copy_dialog_box_details";
                description = (React.createElement("p", { className: "description", id: "schoolConnectionDescription" },
                    React.createElement(Translate_1.Translate, { i18n: "hdr_school_connection_description" })));
                break;
            case JoinInfo_1.JoinType.TflSkype:
                bannerSrc = isSkypeSupportedURL ? tflSkypeBannerImg : tflSkypeUnsupportedBannerImg;
                logo = undefined;
                title = isSkypeSupportedURL ? "hdr_teams_skype_title" : "hdr_teams_skype_unsupported_title";
                getTeamsAndJoinButtonTitle = "btn_join_on_teams_app_title";
                copyToClipboardCheckboxText = "mobile_teams_copy_link_checkbox_generic_label";
                noCopyDialogDetailText = "mobile_teams_skype_no_copy_dialog_box_details";
                description = (React.createElement("p", { className: "description" }, isSkypeSupportedURL ? (React.createElement(Translate_1.Translate, { i18n: "hdr_teams_skype_description" })) : (React.createElement(Translate_1.Translate, { i18n: "hdr_teams_skype_unsupported_description" }))));
                break;
            default:
                illustration = tflConversationInviteImg;
                title = "hdr_mobile_first_conversation";
                getTeamsAndJoinButtonTitle = "btn_mobile_join_conversation";
                copyToClipboardCheckboxText = "mobile_copy_link_checkbox_label";
                noCopyDialogDetailText = "mobile_conversation_no_copy_dialog_box_details";
        }
        title = React.createElement(Translate_1.Translate, { i18n: title });
        return (React.createElement(CoreContext_1.CoreContextConsumer, null, ({ launcherService }) => (React.createElement(JoinLauncherContext_1.JoinLauncherContextConsumer, null, ({ joinInfo }) => {
            var _a;
            this.reportSuccessPanelView("simplified_mobile_join_launcher_loaded", joinInfo);
            // Update experience details from request context
            if (this.props.featureFlags.enableTflRequestContextForMobile) {
                const context = ServerParamsService_1.default.getServerParams().requestContext;
                if (context.avatarImage) {
                    logo = context.avatarImage;
                    logoClass = "logoCustom";
                    illustration = undefined;
                }
                title = (_a = context.title) !== null && _a !== void 0 ? _a : title;
                description = context.description ? (React.createElement("p", { className: "description" }, context.description)) : (description);
                if (isCommunityEventAndEnabled) {
                    const eventDescription = this.getCommunityEventDescription(context);
                    if (eventDescription) {
                        description = eventDescription;
                    }
                    if (this.props.featureFlags.enableTflCommunityEventsAddToCalendar) {
                        addToCalendar = this.getAddToCalendarOption(joinInfo, context);
                    }
                    if (this.props.featureFlags.enableTflCommunityEventsViewDetails) {
                        viewDetails = this.getViewDetailsOption(joinInfo, launcherService);
                    }
                }
                members = context.membersCount ? (React.createElement("p", null,
                    context.membersCount,
                    "\u00A0",
                    React.createElement(Translate_1.Translate, { i18n: "text_members" }))) : undefined;
            }
            return (React.createElement(View_1.View, { className: "modernSimplifiedMobileView" },
                this.state.showNoCopyDialogBox &&
                    this.renderModalDialogBox(noCopyDialogDetailText),
                React.createElement("div", { className: "body column centered" },
                    React.createElement(Spacer_1.Spacer, null),
                    bannerSrc ? (React.createElement("div", { className: "banner" },
                        React.createElement("img", { className: "bannerImg", src: bannerSrc }))) : undefined,
                    logo ? (React.createElement("div", { className: "headerContainer" },
                        React.createElement("div", { className: "logoContainer" },
                            React.createElement(TeamsLogo_1.TeamsLogo, { src: logo, className: logoClass })))) : undefined,
                    illustration ? (React.createElement("img", { className: illustrationClass, src: illustration })) : undefined,
                    React.createElement("div", { className: "contentContainer" },
                        React.createElement("p", { className: "title" }, title),
                        members,
                        description,
                        React.createElement("p", { className: "learnMore" }, joinInfo.type === JoinInfo_1.JoinType.TflSkype ? (React.createElement("a", { className: "learnMore", href: ConfigurationService_1.configurationService.getConfig().urls.absoluteUrls.skypeLearnMoreLink, target: "_blank", rel: "noopener noreferrer", onClick: () => this.reportPanelAction(TelemetryEvents_1.ModuleNames.LearnMore, joinInfo), id: "instructionsLearnMore" },
                            React.createElement(Translate_1.Translate, { i18n: "hdr_btn_learn_more" }))) : null)),
                    this.state.shouldRenderCopyLinkToClipboardCheckbox &&
                        this.renderCopyClipboardCheckBox(copyToClipboardCheckboxText, joinInfo),
                    React.createElement("div", { className: "buttonsContainer" },
                        React.createElement(Button_1.Button, { className: "primary mobile", "data-tid": "getTeamsAndJoinButton", onClick: () => this.smartAction(TelemetryEvents_1.ModuleNames.JoinInApp, joinInfo, launcherService) },
                            React.createElement(Translate_1.Translate, { i18n: this.state.isLaunching
                                    ? "btn_launching_app"
                                    : getTeamsAndJoinButtonTitle })),
                        addToCalendar ? addToCalendar : null,
                        viewDetails ? viewDetails : null),
                    React.createElement("div", { className: "hyperlink" }, joinInfo.type === JoinInfo_1.JoinType.SchoolConnection ? (React.createElement("a", { href: ConfigurationService_1.configurationService.getConfig().urls.absoluteUrls
                            .schoolConnectionLearnMoreLink, target: "_blank", rel: "noopener noreferrer", onClick: () => this.reportPanelAction(TelemetryEvents_1.ModuleNames.LearnMore, joinInfo), id: "instructionsLearnMore" },
                        React.createElement(Translate_1.Translate, { i18n: "hdr_btn_visit_school_connection_website" }))) : joinInfo.type !== JoinInfo_1.JoinType.TflSkype ? (React.createElement("a", { href: ConfigurationService_1.configurationService.getConfig().urls.absoluteUrls.tflJoinIssuesHelpLink, target: "_blank", rel: "noopener noreferrer", onClick: () => this.reportPanelAction(TelemetryEvents_1.ModuleNames.LearnMore, joinInfo), id: "joinConversationTroubleLearnMore" },
                        React.createElement(Translate_1.Translate, { i18n: getHelpJoiningTextLink }))) : null),
                    joinInfo.type !== JoinInfo_1.JoinType.TflSkype && (React.createElement("img", { src: teamsLogoImg, className: "teamsImg", "aria-hidden": true })),
                    !(joinInfo.type === JoinInfo_1.JoinType.SchoolConnection ||
                        joinInfo.type === JoinInfo_1.JoinType.TflSkype)
                        ? this.renderDownloadAppSection(joinInfo)
                        : null,
                    React.createElement(Footer_1.Footer, { onLinkClick: () => this.reportPanelAction(TelemetryEvents_1.ModuleNames.ShowThirdPartyNotice, joinInfo), showTeamsLogo: true }),
                    React.createElement(Spacer_1.Spacer, null))));
        }))));
    }
    renderCopyClipboardCheckBox(label, joinInfo) {
        return (React.createElement("div", { className: "checkboxContainer" },
            React.createElement("input", { type: "checkbox", className: "checkbox", checked: this.state.isCopyToClipboardChecked, onClick: () => {
                    this.reportPanelAction(TelemetryEvents_1.ModuleNames.CopyLinkToClipboard, joinInfo);
                    this.onCheckboxClicked();
                }, readOnly: true }),
            " ",
            TranslationService_1.default.translate(label)));
    }
    getLaunchUrlAndCopyToClipboardAsNeeded(moduleName, launcherService) {
        // Calculate proper launch type
        const launchType = moduleName === TelemetryEvents_1.ModuleNames.ViewDetails ? LaunchType_1.LaunchType.ViewDetails : LaunchType_1.LaunchType.OpenApp;
        // Construct new launch url per launch type
        const launchUrl = launcherService.getProtocolLaunchDeeplinkUrl(launchType);
        // Copy to clipboard as needed
        if (this.state.shouldRenderCopyLinkToClipboardCheckbox && this.state.isCopyToClipboardChecked) {
            Utils_1.Utils.copyTextToMobileClipboard(launchUrl);
        }
        return launchUrl;
    }
    reportSuccessPanelView(name, joinInfo, props) {
        if (!this.finalPanelViewReported) {
            const panelViewEvent = new TelemetryEvents_1.PanelViewEvent(name, joinInfo, Object.assign(Object.assign({}, props), { launchResult: this.props.launchResult }));
            Logger_1.default.logUserBiPanelView(panelViewEvent);
            this.finalPanelViewReported = true;
        }
    }
    reportPanelAction(moduleName, joinInfo) {
        let props;
        if (this.state.shouldRenderCopyLinkToClipboardCheckbox) {
            props = {
                "DataBag.isChecked": this.state.isCopyToClipboardChecked,
            };
        }
        const panelActionEvent = new TelemetryEvents_1.PanelActionEvent(TelemetryEvents_1.PanelActionScenarioNames.ModernSimplifiedMobileView, moduleName, joinInfo, props);
        Logger_1.default.logUserBiPanelAction(panelActionEvent);
    }
    smartAction(moduleName, joinInfo, launcherService) {
        this.setState({ isLaunching: true });
        // Report action
        this.reportPanelAction(moduleName, joinInfo);
        // Mobile Container will make sure to mark smart banner for iOS as seen
        // to enable proper autolaunch in future
        this.props.onLaunchClick();
        // Get new launch url based on intent and copy to clipboard if needed
        // Copying to clipboard has to happen outside the timeout block to be successful
        const launchUrl = this.getLaunchUrlAndCopyToClipboardAsNeeded(moduleName, launcherService);
        // Try first to execute the launch url i.e. "msteams://...."
        // This will open the app otherwise, if Teams is not available, this will be a noop.
        UrlHelper_1.UrlHelper.redirect(launchUrl);
        // After a timeout, if the user is still on the page
        // (or is using Safari due to some limitations) redirect them to store for download
        setTimeout(() => {
            this.setState({ isLaunching: false });
            if (!document.hasFocus() && EnvironmentService_1.default.getBrowserName() !== Environment_1.Browsers.Safari) {
                return;
            }
            this.navUserToStore(launcherService);
        }, storeRedirectionTimeoutMs);
    }
    getCommunityEventDescription(context) {
        if (context.startTime && context.endTime) {
            return (React.createElement("p", { className: "description" },
                CommunityEventsHelper_1.CommunityEventsHelper.getEventDescriptionTimeLine(context),
                React.createElement("br", null),
                CommunityEventsHelper_1.CommunityEventsHelper.getEventDescriptionDateLine(context)));
        }
        return undefined;
    }
    getAddToCalendarOption(joinInfo, context) {
        const blobUrl = CommunityEventsHelper_1.CommunityEventsHelper.createEventICalBlob(context, joinInfo);
        return blobUrl ? (React.createElement(Button_1.Button, { className: "secondary mobile", id: "addToCalendar", onClick: () => {
                this.reportPanelAction(TelemetryEvents_1.ModuleNames.AddToCalendar, joinInfo);
                window.open(blobUrl);
            } },
            React.createElement(Translate_1.Translate, { i18n: "btn_add_to_calendar" }))) : undefined;
    }
    getViewDetailsOption(joinInfo, launcherService) {
        return (React.createElement("div", { className: "viewDetails" },
            React.createElement("a", { id: "viewDetails", onClick: () => this.smartAction(TelemetryEvents_1.ModuleNames.ViewDetails, joinInfo, launcherService) },
                React.createElement(Translate_1.Translate, { i18n: "lnk_view_event_details" }))));
    }
}
exports.ModernSimplifiedMobileView = ModernSimplifiedMobileView;
