"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonsFactory = exports.ButtonType = exports.ButtonKey = void 0;
const React = require("react");
const Button_1 = require("../components/common/modern/Button");
var ButtonKey;
(function (ButtonKey) {
    ButtonKey[ButtonKey["PrimaryContinueInThisBrowser"] = 0] = "PrimaryContinueInThisBrowser";
    ButtonKey[ButtonKey["PrimaryJoinInApp"] = 1] = "PrimaryJoinInApp";
    ButtonKey[ButtonKey["SecondaryJoinInApp"] = 2] = "SecondaryJoinInApp";
    ButtonKey[ButtonKey["CallToActionDownload"] = 3] = "CallToActionDownload";
    ButtonKey[ButtonKey["CallToActionRedirectToDownload"] = 4] = "CallToActionRedirectToDownload";
    ButtonKey[ButtonKey["SecondaryContinueInThisBrowser"] = 5] = "SecondaryContinueInThisBrowser";
})(ButtonKey = exports.ButtonKey || (exports.ButtonKey = {}));
var ButtonType;
(function (ButtonType) {
    ButtonType[ButtonType["Primary"] = 0] = "Primary";
    ButtonType[ButtonType["Secondary"] = 1] = "Secondary";
    ButtonType[ButtonType["CallToAction"] = 2] = "CallToAction";
})(ButtonType = exports.ButtonType || (exports.ButtonType = {}));
class ButtonsFactory {
    constructor(flags, handlers) {
        this.flags = flags;
        this.handlers = handlers;
        this.DataTid = {
            joinInApp: "joinInApp",
            download: "download",
            joinOnWeb: "joinOnWeb",
        };
    }
    /* START: Helper methods to return right set of buttons */
    getEdgeSuccessButtons() {
        return this.getNoBrowserSupportButtons();
    }
    getEdgeFailureButtons() {
        return this.getNoBrowserSupportButtons();
    }
    getChromeLinuxButtons() {
        const isLinux = true;
        return this.getButtons(isLinux);
    }
    getChromeWindowsButtons() {
        const isLinux = false;
        return this.getButtons(isLinux);
    }
    getChromeMacOsButtons() {
        const isLinux = false;
        return this.getButtons(isLinux);
    }
    getChromeUnsupportedOsButtons() {
        return this.getNoOsSupportButtons();
    }
    getSafariMacOsButtons() {
        const isLinux = false;
        return this.getButtons(isLinux);
    }
    getUnsupportedOsButtons() {
        return this.getNoOsSupportButtons();
    }
    getOtherBrowsersWindowsButtons() {
        return this.getNoBrowserSupportButtons();
    }
    getOtherBrowsersMacOsButtons() {
        return this.getNoBrowserSupportButtons();
    }
    getOtherBrowsersLinuxButtons() {
        return this.getNoBrowserSupportButtons();
    }
    getFirefoxMacOsButtons() {
        const isLinux = false;
        return this.getButtons(isLinux);
    }
    getFirefoxWindowsButtons() {
        const isLinux = false;
        return this.getButtons(isLinux);
    }
    getFirefoxLinuxButtons() {
        const isLinux = true;
        return this.getButtons(isLinux);
    }
    /* END: Helper methods to return right set of buttons */
    getNoBrowserSupportButtons() {
        return [
            ...(this.flags.showDesktopButtons ? [this.getButton(ButtonKey.PrimaryJoinInApp)] : []),
            ...(this.flags.showDesktopButtons && !this.flags.hideDownloadButton
                ? [this.getButton(ButtonKey.CallToActionDownload)]
                : []),
        ];
    }
    getNoOsSupportButtons() {
        return [this.getButton(ButtonKey.PrimaryContinueInThisBrowser)];
    }
    getButtons(isLinux) {
        return [
            ...(this.flags.showDesktopButtons && !this.flags.hideDownloadButton
                ? isLinux
                    ? [this.getButton(ButtonKey.CallToActionRedirectToDownload)]
                    : [this.getButton(ButtonKey.CallToActionDownload)]
                : []),
            ...(this.flags.hideContinueButton
                ? []
                : [this.getButton(ButtonKey.PrimaryContinueInThisBrowser)]),
            ...(this.flags.showDesktopButtons
                ? [
                    this.getButton(this.flags.hideContinueButton
                        ? ButtonKey.PrimaryJoinInApp
                        : ButtonKey.SecondaryJoinInApp),
                ]
                : []),
        ];
    }
    getButton(key) {
        switch (key) {
            case ButtonKey.PrimaryJoinInApp:
                return (React.createElement(Button_1.Button, { ariaLabel: "btn_join_in_app_title_aria_label", key: ButtonKey.PrimaryJoinInApp, title: "btn_join_on_teams_app_title", "data-tid": this.DataTid.joinInApp, type: ButtonType.Primary, onClick: this.handlers.joinInApp }));
            case ButtonKey.SecondaryJoinInApp:
                return (React.createElement(Button_1.Button, { ariaLabel: "btn_join_in_app_title_aria_label", key: ButtonKey.SecondaryJoinInApp, title: "btn_join_on_teams_app_title", "data-tid": this.DataTid.joinInApp, type: ButtonType.Secondary, onClick: this.handlers.joinInApp }));
            case ButtonKey.PrimaryContinueInThisBrowser:
                return (React.createElement(Button_1.Button, { ariaLabel: "btn_continue_on_browser_title_aria_label", key: ButtonKey.PrimaryContinueInThisBrowser, title: "btn_continue_on_browser_title", "data-tid": this.DataTid.joinOnWeb, type: ButtonType.Primary, onClick: this.handlers.joinOnWeb }));
            case ButtonKey.SecondaryContinueInThisBrowser:
                return (React.createElement(Button_1.Button, { ariaLabel: "btn_continue_on_browser_title_aria_label", key: ButtonKey.SecondaryContinueInThisBrowser, title: "btn_continue_on_browser_title", "data-tid": this.DataTid.joinOnWeb, type: ButtonType.Secondary, onClick: this.handlers.joinOnWeb }));
            case ButtonKey.CallToActionDownload:
                return (React.createElement(Button_1.Button, { ariaLabel: "txt_download_text_aria_label", key: ButtonKey.CallToActionDownload, title: "txt_download_title", text: "txt_download_text", "data-tid": this.DataTid.download, type: ButtonType.CallToAction, onClick: this.handlers.download }));
            case ButtonKey.CallToActionRedirectToDownload:
                return (React.createElement(Button_1.Button, { ariaLabel: "txt_download_text_aria_label", key: ButtonKey.CallToActionRedirectToDownload, title: "txt_download_title", text: "txt_download_text", "data-tid": this.DataTid.download, type: ButtonType.CallToAction, onClick: this.handlers.redirectToDownloadPage }));
            default:
                return React.createElement("div", { className: "not_implemented" });
        }
    }
}
exports.ButtonsFactory = ButtonsFactory;
