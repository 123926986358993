"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logger = exports.CommonTrackingProperties = void 0;
const webjs_sdk_1 = require("@aria/webjs-sdk");
const Utils_1 = require("../helpers/Utils");
const TelemetryEvents_1 = require("../models/telemetry/TelemetryEvents");
const EnvironmentService_1 = require("./EnvironmentService");
const FeaturesService_1 = require("./FeaturesService");
const ServerParamsService_1 = require("./ServerParamsService");
const ConfigurationService_1 = require("./ConfigurationService");
var CommonTrackingProperties;
(function (CommonTrackingProperties) {
    CommonTrackingProperties["ScreenResolution"] = "screenResolution";
    CommonTrackingProperties["DeeplinkType"] = "deeplinkType";
    CommonTrackingProperties["LaunchSource"] = "launchSource";
    CommonTrackingProperties["Context"] = "context";
})(CommonTrackingProperties = exports.CommonTrackingProperties || (exports.CommonTrackingProperties = {}));
var TelemetryType;
(function (TelemetryType) {
    TelemetryType["Tracking"] = "tracking";
    TelemetryType["Scenario"] = "scenario";
    TelemetryType["UserBi"] = "userbi";
})(TelemetryType || (TelemetryType = {}));
class Logger {
    constructor() {
        this.TRACKING_EVENT_TYPE = "launcher";
        this.USERBI_PANELVIEW_TYPE = "panelview";
        this.USERBI_PANELACTION_TYPE = "panelaction";
        this.logger = webjs_sdk_1.AWTLogManager.getLogger();
        this.commonTrackingProps = {};
        this.logUserBiPanelView = (event, priority = webjs_sdk_1.AWTEventPriority.Normal) => {
            const awtEventProperties = new webjs_sdk_1.AWTEventProperties();
            awtEventProperties.setName(TelemetryType.UserBi);
            awtEventProperties.setType(this.USERBI_PANELVIEW_TYPE);
            this.setAwtEventProperties(this.commonTrackingProps, awtEventProperties);
            this.setTabVisibility(awtEventProperties);
            awtEventProperties.setEventPriority(priority);
            if (event.properties) {
                this.setAwtEventProperties(event.properties, awtEventProperties);
            }
            this.logger.logEvent(awtEventProperties);
            this.lastReportedPanelViewType = event.properties["Panel.Type"];
        };
        this.logUserBiPanelAction = (event, priority = webjs_sdk_1.AWTEventPriority.Normal) => {
            const awtEventProperties = new webjs_sdk_1.AWTEventProperties();
            awtEventProperties.setName(TelemetryType.UserBi);
            awtEventProperties.setType(this.USERBI_PANELACTION_TYPE);
            this.setAwtEventProperties(this.commonTrackingProps, awtEventProperties);
            this.setTabVisibility(awtEventProperties);
            awtEventProperties.setEventPriority(priority);
            const props = event.properties;
            if (props) {
                props["Panel.Type"] = this.lastReportedPanelViewType;
                this.setAwtEventProperties(props, awtEventProperties);
            }
            this.logger.logEvent(awtEventProperties);
        };
        this.log = (event, priority = webjs_sdk_1.AWTEventPriority.Normal) => {
            const awtEventProperties = new webjs_sdk_1.AWTEventProperties();
            awtEventProperties.setName(TelemetryType.Tracking);
            awtEventProperties.setType(this.TRACKING_EVENT_TYPE);
            awtEventProperties.setProperty("actionName", event.name);
            this.setAwtEventProperties(this.commonTrackingProps, awtEventProperties);
            this.setTabVisibility(awtEventProperties);
            awtEventProperties.setEventPriority(priority);
            if (event.properties) {
                this.setAwtEventProperties(event.properties, awtEventProperties);
            }
            this.logger.logEvent(awtEventProperties);
        };
        this.logScenarioStep = (event, priority = webjs_sdk_1.AWTEventPriority.Normal) => {
            const awtEventProperties = new webjs_sdk_1.AWTEventProperties();
            awtEventProperties.setName(TelemetryType.Scenario);
            awtEventProperties.setType(event.name);
            this.setTabVisibility(awtEventProperties);
            awtEventProperties.setEventPriority(priority);
            if (event.properties) {
                this.setAwtEventProperties(event.properties, awtEventProperties);
            }
            this.logger.logEvent(awtEventProperties);
        };
    }
    init() {
        webjs_sdk_1.AWTLogManager.initialize(ConfigurationService_1.configurationService.getConfig().telemetry.tenantToken, {
            enableAutoUserSession: true,
            collectorUri: ConfigurationService_1.configurationService.getConfig().ariaCollectorUri,
        });
        this.setDefaultContext();
    }
    setContext(deeplinkIds, deeplinkType, userInfo, launchSource, context) {
        if (deeplinkIds.server) {
            webjs_sdk_1.AWTLogManager.setContext("serverDeeplinkId", deeplinkIds.server);
        }
        if (deeplinkIds.client) {
            webjs_sdk_1.AWTLogManager.setContext("deeplinkId", deeplinkIds.client);
        }
        if (deeplinkIds.web) {
            webjs_sdk_1.AWTLogManager.setContext("webDeeplinkId", deeplinkIds.web);
        }
        if (userInfo) {
            if (userInfo.tenantModel) {
                webjs_sdk_1.AWTLogManager.setContext("Tenant.Model", userInfo.tenantModel);
            }
            if (userInfo.ring) {
                webjs_sdk_1.AWTLogManager.setContext("UserInfo.Ring", userInfo.ring);
            }
            if (userInfo.isPossiblyBot) {
                webjs_sdk_1.AWTLogManager.setContext("UserInfo.IsPossiblyBot", userInfo.isPossiblyBot);
            }
        }
        webjs_sdk_1.AWTLogManager.setContext("clientEnvironment", EnvironmentService_1.default.getBuildEnvironment());
        this.commonTrackingProps[CommonTrackingProperties.ScreenResolution] =
            EnvironmentService_1.default.getScreenResolution();
        this.commonTrackingProps[CommonTrackingProperties.DeeplinkType] = Utils_1.Utils.scrubEuii(deeplinkType || "");
        this.commonTrackingProps[CommonTrackingProperties.LaunchSource] = launchSource;
        this.commonTrackingProps[CommonTrackingProperties.Context] = context;
    }
    setMeetingIdContext(meetingId) {
        if (meetingId) {
            webjs_sdk_1.AWTLogManager.setContext("Meeting.Id", meetingId);
        }
    }
    setExperimentContext(configIDs, flags) {
        webjs_sdk_1.AWTLogManager.setContext("ecsConfigIDs", JSON.stringify(configIDs));
        // adds relevant feature flags to the context
        webjs_sdk_1.AWTLogManager.setContext("flags", JSON.stringify({
            enableAutoDownload: !!flags.enableAutoDownload,
            enableWebJoinForNewVisitors: !!flags.enableWebJoinForNewVisitors,
        }));
    }
    setAnonymousContext(pdsAnonymousParam, localAnonymous) {
        webjs_sdk_1.AWTLogManager.setContext("serverAnonymous", pdsAnonymousParam);
        webjs_sdk_1.AWTLogManager.setContext("clientAnonymous", localAnonymous);
    }
    // DGJ stands for Direct Guest Join. More information:
    // https://learn.microsoft.com/en-us/microsoftteams/rooms/third-party-join
    setDGJPartnerIdContext(dgjPartnerId) {
        if (dgjPartnerId) {
            webjs_sdk_1.AWTLogManager.setContext("dgjPartnerId", dgjPartnerId);
        }
    }
    setEnvironmentContext(env) {
        // Environment info should have necessary information fetched from UA-CH which is more precise than the default one from userAgent
        // For now adjusting context based on available platform version
        if (env.highEntropyPlatformVersion) {
            const semanticContext = webjs_sdk_1.AWTLogManager.getSemanticContext();
            semanticContext.setDeviceOsVersion(env.highEntropyPlatformVersion);
        }
    }
    flushAndTeardown() {
        webjs_sdk_1.AWTLogManager.flushAndTeardown();
    }
    setDefaultContext() {
        // this is type issue in ARIA SDK
        webjs_sdk_1.AWTLogManager.setContext("launcherStartTime", new Date().toString(), webjs_sdk_1.AWTPropertyType.Date);
        webjs_sdk_1.AWTLogManager.setContext("userAgent", Utils_1.Utils.scrubEuii(EnvironmentService_1.default.getUserAgent()));
        webjs_sdk_1.AWTLogManager.setContext("environment", ConfigurationService_1.configurationService.getConfig().env);
        webjs_sdk_1.AWTLogManager.setContext("suppressPrompt", FeaturesService_1.default.getFeatureFlags().suppressPrompt);
        webjs_sdk_1.AWTLogManager.setContext("promptSuccess", FeaturesService_1.default.getFeatureFlags().promptSuccess);
        webjs_sdk_1.AWTLogManager.setContext("launcherVersion", EnvironmentService_1.default.getLauncherVersion());
        // adds relevant feature flags to the context
        webjs_sdk_1.AWTLogManager.setContext("flags", JSON.stringify({
            enableBranding: !!FeaturesService_1.default.getFeatureFlags().enableBranding,
            brandingWithImages: !!FeaturesService_1.default.getFeatureFlags().brandingWithImages,
            enableT21WebRedirect: !!FeaturesService_1.default.getFeatureFlags().enableT21WebRedirect,
            lightMeetingsBuildVersion: FeaturesService_1.default.getFeatureFlags().lightMeetingsBuildVersion,
            lightMeetingsWebBuildVersion: FeaturesService_1.default.getFeatureFlags().lightMeetingsWebBuildVersion,
            enablePDSRedirect: !!FeaturesService_1.default.getFeatureFlags().enablePDSRedirect,
            enableLimeRedirectFromWebjoin: !!FeaturesService_1.default.getFeatureFlags().enableLimeRedirectFromWebjoin,
            enableLimeRedirectForMeetingId: !!FeaturesService_1.default.getFeatureFlags().enableLimeRedirectForMeetingId,
            enableLimeRedirectFromUnsupportedOS: !!FeaturesService_1.default.getFeatureFlags().enableLimeRedirectFromUnsupportedOS,
            enableAnonymousReporting: !!FeaturesService_1.default.getFeatureFlags().enableAnonymousReporting,
            enableLightMeetingsHostnameFromUrl: !!FeaturesService_1.default.getFeatureFlags().enableLightMeetingsHostnameFromUrl,
        }));
        // Set context related to requestContext parameters
        this.setRequestContext();
    }
    setRequestContext() {
        const requestContext = ServerParamsService_1.default.getServerParams().requestContext;
        webjs_sdk_1.AWTLogManager.setContext("launcherSessionId", requestContext.sessionId);
        // Mark if request context details were provded by PDS, title as main property is good approximation for now
        if (requestContext.title) {
            webjs_sdk_1.AWTLogManager.setContext(TelemetryEvents_1.DataBagProps.RequestContextProvided, true);
        }
        // Community event related parameters needed for tracking
        if (requestContext.eventType) {
            webjs_sdk_1.AWTLogManager.setContext(TelemetryEvents_1.CommunityEventProps.EventType, requestContext.eventType);
        }
        if (requestContext.meetingType) {
            webjs_sdk_1.AWTLogManager.setContext(TelemetryEvents_1.CommunityEventProps.MeetingType, requestContext.meetingType);
        }
        if (requestContext.exchangeId) {
            webjs_sdk_1.AWTLogManager.setContext(TelemetryEvents_1.CommunityEventProps.EventId, requestContext.exchangeId);
        }
        if (requestContext.communityThreadId) {
            webjs_sdk_1.AWTLogManager.setContext(TelemetryEvents_1.CommunityEventProps.CommunityId, requestContext.communityThreadId);
        }
    }
    setAwtEventProperties(source, awtEventProperties) {
        Object.keys(source).forEach(property => {
            const propertyValue = source[property];
            if (propertyValue !== undefined) {
                awtEventProperties.setProperty(property, propertyValue);
            }
        });
    }
    setTabVisibility(awtEventProperties) {
        this.setAwtEventProperties({
            isTabVisible: Utils_1.Utils.isTabVisible(),
        }, awtEventProperties);
    }
}
exports.Logger = Logger;
const logger = new Logger();
exports.default = logger;
